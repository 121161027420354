<nav class="nav-container" [ngClass]="{'mobile': mobile}">
    <div class="nav-logo" routerLink='' *appMobile="'browser'">
        <nb-icon class="nb-icon" *ngIf="currentEvent === 'none'" icon="actintech-icon" pack="custom-icons"></nb-icon>
        <nb-icon class="nb-icon" *ngIf="currentEvent === 'Halloween'" icon="actintech-icon-pumpkin" pack="custom-icons"></nb-icon>
        <nb-icon class="nb-icon" *ngIf="currentEvent === 'Christmas'" icon="actintech-icon-christmas" pack="custom-icons"></nb-icon>
        <nb-icon class="nb-icon" *ngIf="currentEvent === 'Summer'" icon="actintech-icon-summer" pack="custom-icons"></nb-icon>
    </div>
    <div class="nav-menu">
        <ul class="navbar-nav">
            <li [ngClass]="{'nav-selected': currentUrl==='/'}"
                routerLink=''>
                <nb-icon class="nb-icon mb-1" icon="home-icon" pack="custom-icons"></nb-icon>
                <span>Accueil</span>
            </li>
            <li [ngClass]="{'nav-selected': currentUrl==='/events' || isEventPage}"
                routerLink='events'>
                <nb-icon class="nb-icon mb-1" icon="calendar-icon" pack="custom-icons"></nb-icon>
                <span>Événements</span>
            </li>
            <li [ngClass]="{'nav-selected': currentUrl==='/ticketing'}"
                routerLink='ticketing'>
                <nb-icon class="nb-icon mb-1" icon="ticket-icon" pack="custom-icons"></nb-icon>
                <span>Billetterie</span>
            </li>

            <!-- This menu will disapear in the next version as the feature is not really used -->
            <li *ngIf="false"
                [ngClass]="{'nav-selected': currentUrl==='/catalog'}"
                routerLink='catalog'>
                <nb-icon class="nb-icon mb-1" icon="catalog-icon" pack="custom-icons"></nb-icon>
                <span>Modèles</span>
            </li>
            <!-- -->

            <li *appMobile="'browser'"
                [ngClass]="{'nav-selected': currentUrl==='/croissant'}"
                routerLink='croissant'>
                <nb-icon class="nb-icon mb-1" icon="croissant-icon" pack="custom-icons"></nb-icon>
                <span>Croissants</span>
            </li>
            <li [ngClass]="{'nav-selected': currentUrl==='/admin'}"
                routerLink='admin'
                *ngIf="!mobile && isAdmin">
                <nb-icon class="nb-icon mb-1" icon="admin-icon" pack="custom-icons"></nb-icon>
                <span>Administration</span>
            </li>
            <li *appMobile="'mobile'"
                [ngClass]="{'nav-selected': currentUrl==='/more-nav'}"
                routerLink='more-nav'>
                <nb-icon class="nb-icon" icon="more-horizontal-outline"></nb-icon>
                <span *ngIf="!(currentUrl==='/more-nav'); else moreNav">Plus</span>
                <ng-template #moreNav>
                    <span>Moins</span>
                </ng-template>
            </li>
        </ul>
    </div>
    <div class="nav-logout" *appMobile="'browser'">
        <img class="img-logout" src="{{imageUrl}}" alt="" *ngIf="imageUrl; else defaultImage">

        <ng-template #defaultImage>
            <div class="img-logout">
                <span>{{name}}</span>
            </div>
        </ng-template>
        <a (click)="openChangelog()"><br>Version 1.6.2</a>
        <span class="mt-1"></span>
        <a (click)="onLogout()">Déconnexion</a>
    </div>
</nav>
